<template>
  <section class="who-we-are">
    <b-container>
      <b-row>
        <b-col md="6" class="margin-left-auto" v-if="$route.path === '/'">
          <article class="text-center article">
            <h1 class="article__head">{{ $t("title.about_us") }}</h1>
            <read-more
              more-str=""
              less-str=""
              :text="aboutUs"
              class="article__body mb-5"
              :max-chars="115"
            ></read-more>
            <router-link to="/about-us" class="router-link mt-3"
              ><span>{{ $t("button.more_info") }}</span></router-link
            >
          </article>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "WhoWeAre",
  props: {
    aboutUs: {
      type: String,
      default: ""
    }
  }
};
</script>
